import * as React from "react";

import Layout from "../components/layout/layout";
import PrivacyPolicy from "../components/privacy_policies/privacy_policy";
import Seo from "../components/seo";

const PolitiqueDeCookies = () => {
  return (
    <>
      <Seo
        title="Politique De Cookies"
        description="Politique De Cookies."
      />
      <Layout>
        <PrivacyPolicy />
      </Layout>
    </>
  )
}

export default PolitiqueDeCookies;
