import React from "react";

import BannerWrapperCustomImg from "../../top_banner/banner_wrapper_custom_img";
import { useTranslation } from 'react-i18next';

const PrivacyPolicy = () => {
  const {t} = useTranslation();
  return (
    <div className={`conatiner font-sans`}>
      <BannerWrapperCustomImg
        largeBgImg={"https://d2lxkizvrhu4im.cloudfront.net/images/background-cookie-banner.png"}
        nonlargeBgImg={"https://d2lxkizvrhu4im.cloudfront.net/images/background-cookie-banner.png"}
      >
        <div className=" flex flex-col  justify-center items-center h-full py-20  mx-auto">
          <div class="font-bold text-3xl md:text-6xl text-center">
            <h1>{t('gdpr_privacy_policy')}</h1>
          </div>
          <div class=" text-sm md:text-xl  font-medium  text-center  lg:text-left leading-relaxed px-1 md:px-0">
            {t('last_updated_on_may_2_2022')}
          </div>
        </div>
      </BannerWrapperCustomImg>

      <div className={`mx-auto w-11/12 lg:w-5/6 mb-16`}>
        <div>
          <div className="my-8 font-bold text-xl">
            RGPD : AVANT-PROPOS
          </div>
          <div className="">
           {t('the_european_general_data')}
            <br /><br />
            {t('mereos_is_in_constant_process')}
            <br /><br />
            {t('it_should_be_noted_that')}
            <br /><br />
            {t('among_the_measures_implemented')}
            <div className="ml-8">
              <li>
                {t('the_establishment_of_clear')}
              </li>
              <li>
                {t('the_keeping_by_mereos_of')}
              </li>
              <li>
                {t('the_implementation_of_process')}
              </li>
            </div>
           {t('additional_information')}
          </div>
        </div>

        <div>
          <div className="my-8 font-bold text-xl">
            {t('gdpr_foreword')}
          </div>
          <div className="">
            {t('at_mereos_one_of_our_priorities')} <a href='mailto:contact@mereos.eu' className="text-blue-600 underline">contact@mereos.eu</a>
          </div>

          <div className="my-4 font-bold">
            {t('mereos_processes_the_data')}
          </div>
          <div>
            {t('your_institution_decides')}
            <br /><br />
            {t('we_always_establish_data_processing')}
            <br /><br />
            {t('before_designing_our_products')}
          </div>

          <div className="my-4 font-bold">
            {t('what_personal_data_do')}
          </div>
          <div>
            {t('the_personal_data_that_you_agree')}
            <div className="ml-8">
              <li>
                {t('the_name_of_candidate')}
              </li>
              <li>
                {t('the_candidate_email_address')}
              </li>
              <li>
                {t('the_student_employee_or_applicant')}
              </li>
              <li>
                {t('the_data_present_on_the_identity_document')}
              </li>
              <li>
                {t('the_candidate_ip_address')}
              </li>
              <li>
                {t('recording_of_candidate_screen')}
              </li>
              <li>
                {t('webcam_recording_of_your_exam')}
              </li>
              <li>
                {t('the_associated_establishment')}
              </li>
              <li>
                {t('data_related_to_your_session')}
              </li>
            </div>
            {t('mereos_uses_this_data_identify')}
          </div>

          <div className="my-4 font-bold">
            {t('what_data_do_we_automatically_collect')}
          </div>
          <div>
            {t('we_can_process_and_store')}
            <div className="ml-8">
              <li>
                {t('information_about_the_candidate_browser')}
              </li>
              <li>
                {t('the_candidate_ip_address')}
              </li>
            </div>
            {t('this_data_is_necessary_both')}
            <br />
            {t('we_also_log_user_related')}
          </div>

          <div className="my-4 font-bold">
            {t('who_can_access_my_data_in_mereos')}
          </div>

          <div className="">
            {t('personnel_authorized')}
            <br />
            {t('mereos_may_also_call_on_other_companies')}
            <br />
            {t('our_provider_amazon_web_services')}
            <br />
            {t('if_your_institution_has')}
          </div>

          <div className="my-4 font-bold">
            {t('how_does_mereos_store_and_protect')}
          </div>
          <div className="">
            {t('as_explained_previously_one_mereos')}
            <br /> <br />
            {t('our_privacy_protocols_are_follows')}
            <div className="ml-8">
              <li>
                <b>Minimisation</b>: {t('we_only_collect_the_data_necessary')}
              </li>
              <li>
                <b>Anonymisation</b>: {t('we_anonymize_the_data_before')}
              </li>
              <li>
                <b>{t('security')}</b>: {t('candidate_personal_data')}   
              </li>
            </div>
          </div>
        </div>

        <div>
          <div className="my-8 font-bold text-xl">
            {t('confidential_outside_a_test')}
          </div>
          <div className="">
            {t('at_mereos_one_of_our_priorities')} <a href='mailto:contact@mereos.eu' className="text-blue-600 underline">contact@mereos.eu</a>
            <div className="my-4 font-bold">
              {t('mereos_as_data_controller')}
            </div>
            <div className="">
              {t('mereos_stores_and_processes_customer')}
              <br /><br />
              {t('we_only_do_this_when_we_have_legal_basis')}
              <br /><br />
              {t('under_no_circumstances')}
            </div>

            <div className="my-4 font-bold">
              {t('what_information_do_we_collect')}
            </div>
            <div>
              {t('we_collect_data_that_you_explicitly')}
              <div className="">
                <li>{t('request_for_information_via_brochures')}</li>
                <li>{t('register_for_a_free_trial')}</li>
              </div>
            </div>

            <div>
              {t('we_collect_your_ip_address_when_you_visit')}
              <br /><br />
              {t('we_also_record_and_process_personal')}
            </div>

            <div className="my-4 font-bold">
              {t('mereos_data_privacy_obligations')}
            </div>
            <div>
              {t('mereos_strives_to_ensure')}
              <div className="ml-8">
                <li>{t('are_treated_fairly_and_legally')}</li>
                <li>{t('are_obtained_only_for_specific_purposes')}</li>
                <li>{t('are_not_excessmy')}</li>
                <li>{t('are_secure_and_inaccessible')}</li>
              </div>
            </div>

            <div className="my-4 font-bold">
              {t('privacy_policy_changes')}
            </div>
            <div>
              {t('any_information_we_collect')}
            </div>
          </div>
        </div>

        <div>
          <div className="my-8 font-bold text-xl">
            {t('our_commitment')}
          </div>
          <div className="">
            {t('when_taking_an_exam_remotely')}
            <div className="my-4 font-bold">
              {t('what_personal_data_does_mereos_collect')}
            </div>
            <div className="">
              {t('the_exam_organizers_will_decide')}
            </div>

            <div className="my-4 font-bold">
              {t('how_do_know_my_data_is_safe')}
            </div>
            <div className="">
              {t('the_roles_available_on_our_back')}
            </div>

            <div className="my-4 font-bold">
              {t('will_the_proctor_be_able_to_access_my_computer')}
            </div>
            <div className="">
              {t('mereos_proctors_never_have_access')}
            </div>

            <div className="my-4 font-bold">
              {t('why_am_being_asked_to_download')}
            </div>
            <div className="">
              {t('with_the_mereos_extension')}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
